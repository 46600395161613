import React from "react"

const CheckboxGroup = ({
  componentEC,
  label,
  name,
  options,
  selectedValues,
  onChange,
}) => {
  const handleCheckboxChange = e => {
    const { value, checked } = e.target

    // Update the selected values based on the checkbox state
    const updatedValues = checked
      ? [...selectedValues, value] // Add the value if checked
      : selectedValues.filter(item => item !== value) // Remove if unchecked

    onChange(name, updatedValues) // Pass updated values to parent
  }

  return (
    <div className={`flex-[1] flex flex-col gap-[10px] ${componentEC}`}>
      <label className="block text-[#25282C] text-[18px] leading-[1.4em] font-[600]">
        {label}
      </label>
      <div className="flex justify-start flex-wrap gap-4 md:gap-10">
        {options.map(option => (
          <label
            key={option.value}
            className="flex items-start min-w-[110px] text-[#7F7F7F] text-[16px] font-[400] transition-all lg:max-w-[30%]"
          >
            <input
              type="checkbox"
              name={name}
              value={option.value}
              checked={selectedValues.includes(option.value)}
              onChange={handleCheckboxChange}
              className="mr-2 min-w-[18px] relative top-[3px]"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default CheckboxGroup
