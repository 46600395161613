import React from "react"

const RadioButtons = ({
  componentEC,
  label,
  name,
  options,
  value,
  onChange,
}) => {
  return (
    <div className={`flex-[1] flex flex-col gap-[10px] ${componentEC}`}>
      <label className="text-[#25282C] text-[18px] leading-[1.4em] font-[600]">
        {label}
      </label>
      <div className="flex justify-start flex-wrap gap-10">
        {options.map(option => (
          <label
            key={option.value}
            className={`flex items-center gap-[7px] min-w-[110px] text-[#7F7F7F] text-[16px] font-[400] transition-all`}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioButtons
