import React, { useState } from "react"
import InputField from "../../components/atoms/InputField"
import RadioButtonsAsToggleButtons from "../../components/atoms/RadioButtonsAsToggleButtons"
import RadioButtons from "../../components/atoms/RadioButtons"
import CheckboxGroup from "../../components/atoms/CheckboxGroup"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/molecules/InnerHeader"
import ProgressBarForFormSteps from "../../components/atoms/ProgressBarForFormSteps"
import SelectField from "../../components/atoms/SelectField"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import BrandParagraph from "../../components/atoms/BrandParagraph"
import IconListItem from "../../components/atoms/IconListItem"

const IntakeForm = () => {
  const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({
    application_date: "",
    preferred_property: "",
    participant_state: "",
    gender: "male",
    participant_date_of_birth: "",
    participant_primary_diagnosis: "",
    participant_secondary_diagnosis: "",
    identify_as_aboriginal_or_torres_strait_islander: "yes-aboriginal",
    preferred_language: "",
    do_you_need_an_interpreter: "Yes",
    are_you_an_ndis_participant: "Yes",
    do_you_have_an_ndis_plan: "Yes",
    ndis_participant_number_name: "",
    do_you_have_an_ndis_plan___no: "",
    is_specialist_disability_accommodation_eligibility_confirmed_in_your_approved_NDIS_plan:
      "Yes",
    if_yes_please_specify_the_sda_building_type: "",
    if_yes_please_specify_the_sda_design_category: "",
    if_yes_please_provide_the_sda_funding_amount_approved_in_the_plan: "",
    do_you_receive_the_disability_support_pension: "Yes",
    do_you_receive_the_commonwealth_rental_assistance: "Yes",
    if_yes_amount_per_fortnight___disability_support_pension: "",
    if_yes_amount_per_fortnight___commonwealth_rental_assistance: "",
    support_coordinator: "",
    organisation: "",
    email: "",
    phone_include_area_code: "",
    primary_contact_person_name: "",
    primary_contact_person_phone: "",
    primary_contact_person_relationship_or_organisation: "",
    primary_contact_person_address: "",
    primary_contact_person_suburb: "",
    primary_contact_person_postcode: "",
    primary_contact_person_state: "",
    primary_contact_person_email: "",
    person_completing_this_form: "",
    person_completing_this_form_phone: "",
    person_completing_this_form_relationship_or_organisation: "",
    person_completing_this_form_address: "",
    person_completing_this_form_suburb: "",
    person_completing_this_form_postcode: "",
    person_completing_this_form_state: "",
    person_completing_this_form_email: "",
    contact_person_for_additional_information: "",
    contact_person_for_additional_information_phone: "",
    contact_person_for_additional_information_relationship_or_organisation: "",
    contact_person_for_additional_information_address: "",
    contact_person_for_additional_information_suburb: "",
    contact_person_for_additional_information_postcode: "",
    contact_person_for_additional_information_state: "",
    contact_person_for_additional_information_email: "",
    do_any_of_the_following_apply_to_you: [],
    please_describe_your_current_living_situation: [],
    please_describe_your_current_living_situation___other: "",
    please_describe_your_previous_living_arrangements_over_the_past_5_years: "",
    please_tell_us_about_your_personality: "",
    please_tell_us_about_your_hobbies_and_interests: "",
    how_do_you_prefer_to_communicate: [],
    are_you_an_ndis_participant_step7: "",
    are_you_an_ndis_participant_step7__yes_who_completed_this_assessment: "",
    are_you_an_ndis_participant_step7__yes_date_of_assessment: "",
    how_do_you_express_your_feelings: "",
    how_do_you_understand_others: "",
    if_you_communicate_non_verbally_how_do_you_make_your_needs_known: "",
    do_you_use_any_equipment: "Yes",
    do_you_assistance_to_use_the_equipment: "Yes",
    will_staff_require_specific_training_to_help_you_use_the_equipment: "Yes",
  })

  const supportOptions = [
    { value: "no_help", label: "No help" },
    { value: "uses_aids", label: "Uses aids" },
    { value: "promoting", label: "Prompting" },
    { value: "some_support", label: "Some support" },
    { value: "full_support", label: "Full support" },
  ]

  const activities = [
    {
      name: "showering_bathing",
      label: "Showering & bathing",
    },
    {
      name: "grooming",
      label: "Grooming",
    },
    {
      name: "dressing",
      label: "Dressing",
    },
    {
      name: "toileting",
      label: "Toileting",
    },
    {
      name: "eating",
      label: "Eating",
    },
    {
      name: "cooking",
      label: "Cooking",
    },
    {
      name: "domestic_activities",
      label: "Domestic activities",
    },
    {
      name: "using_money",
      label: "Using money",
    },
    {
      name: "decision_making",
      label: "Decision making",
    },
    {
      name: "taking_medication",
      label: "Taking medication",
    },
    {
      name: "mobility",
      label: "Mobility",
    },
  ]

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (name, updatedValues) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: updatedValues,
    }))
  }

  const handleRadioChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSelectChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [rows, setRows] = useState([
    {
      id: Date.now(),
      phone: "",
      relationship: "",
    },
  ])

  const addRow = () => {
    setRows(prevRows => [
      ...prevRows,
      { id: Date.now(), phone: "", relationship: "" },
    ])
  }

  const removeRow = id => {
    setRows(prevRows => prevRows.filter(row => row.id !== id))
  }
  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const handleSubmit = e => {
    e.preventDefault()
    alert("Form submitted!")
    console.log(formData)
  }

  return (
    <Layout>
      <Seo title="Intake Form" />
      <InnerHeader
        componentEC={`!mb-0`}
        componentInnerWrapEC={`!pt-12 md:!pt-6 md:!pb-12`}
        textContentBoxEC={`max-w-[1086px] mx-auto flex flex-col justify-center items-center`}
        title={`<span>Intake</span> Form`}
        description={`This form is to be used for a person to apply for a position in specialist disability accommodation (SDA). Please provide the information in each section below.`}
        descriptionEC={`text-center`}
        isShowGoogleReview={false}
      />
      <div className="cc-section--horizontal-space pt-10">
        <h2 className="font-ubuntu text-center text-[28px] font-[500] pb-6">
          SDA Application Form
        </h2>
        <form onSubmit={handleSubmit}>
          {/* Progress Bar with Bubbles */}
          <ProgressBarForFormSteps steps={steps} activeStep={step} />

          {step === 1 && (
            <div>
              <div>
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Property information
                </h3>
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="Application date (Day / Month / Year)"
                    name="application_date"
                    type="date"
                    value={formData.application_date}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Preferred property (if known)"
                    name="preferred_property"
                    value={formData.preferred_property}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="mt-10">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Applicant details
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Participant name"
                      name="participant_name"
                      value={formData.participant_name}
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Email"
                      name="participant_email"
                      value={formData.preferred_property}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Address"
                      name="participant_address"
                      value={formData.participant_address}
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Suburb"
                      name="participant_suburb"
                      value={formData.participant_suburb}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Postcode"
                        name="participant_postcode"
                        value={formData.participant_postcode}
                        onChange={handleInputChange}
                      />
                      <SelectField
                        label="State"
                        name="participant_state"
                        value={formData.participant_state}
                        onChange={handleSelectChange}
                        options={[
                          { value: "", label: "--Select--" },
                          { value: "nsw", label: "New South Wales" },
                          { value: "qld", label: "Queensland" },
                          { value: "sa", label: "South Australia" },
                          { value: "tas", label: "Tasmania" },
                          { value: "vic", label: "Victoria" },
                          { value: "wa", label: "Western Australia" },
                          {
                            value: "act",
                            label: "Australian Capital Territory",
                          },
                          { value: "nt", label: "Northern Territory" },
                        ]}
                      />
                    </div>
                    <InputField
                      label="Phone (include area code)"
                      name="participant_phone"
                      value={formData.participant_phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <RadioButtonsAsToggleButtons
                      label="Gender"
                      name="gender"
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                      ]}
                      value={formData.gender}
                      onChange={handleRadioChange}
                    />
                    <InputField
                      label="Date of birth"
                      name="participant_date_of_birth"
                      type="date"
                      value={formData.participant_date_of_birth}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Primary diagnosis"
                      name="participant_primary_diagnosis"
                      value={formData.participant_primary_diagnosis}
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Secondary diagnoses"
                      name="participant_secondary_diagnosis"
                      value={formData.participant_secondary_diagnosis}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center mt-12">
                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <div className="flex flex-col gap-[30px]">
                <RadioButtons
                  label="Do you identify as Aboriginal or Torres Strait Islander?"
                  name="identify_as_aboriginal_or_torres_strait_islander"
                  options={[
                    { value: "yes-aboriginal", label: "Yes, Aboriginal" },
                    {
                      value: "yes-torres-strait-islander",
                      label: "Yes, Torres Strait Islander",
                    },
                    {
                      value: "yes-both-aboriginal-and-torres-strait-islander",
                      label: "Yes, both Aboriginal and Torres Strait Islander",
                    },
                    { value: "No", label: "No" },
                  ]}
                  value={
                    formData.identify_as_aboriginal_or_torres_strait_islander
                  }
                  onChange={handleRadioChange}
                />
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="What is your preferred language?"
                    name="preferred_language?"
                    type="date"
                    value={formData.preferred_language}
                    onChange={handleInputChange}
                  />
                  <RadioButtons
                    label="Do you need an interpreter?"
                    name="do_you_need_an_interpreter"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={formData.do_you_need_an_interpreter}
                    onChange={handleRadioChange}
                  />
                </div>
              </div>

              <div className="mt-10">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  NDIS
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <div className="flex-[1] flex flex-col gap-[15px] md:gap-[30px]">
                      <RadioButtons
                        label="Are you an NDIS participant?"
                        name="are_you_an_ndis_participant"
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        value={formData.are_you_an_ndis_participant}
                        onChange={handleRadioChange}
                      />
                      {formData.are_you_an_ndis_participant === "Yes" ? (
                        <InputField
                          label="NDIS participant number name"
                          name="ndis_participant_number_name"
                          value={formData.ndis_participant_number_name}
                          onChange={handleInputChange}
                        />
                      ) : null}
                    </div>
                    <div className="flex-[1] flex flex-col gap-[15px] md:gap-[30px]">
                      <RadioButtons
                        label="Do you have an NDIS plan?"
                        name="do_you_have_an_ndis_plan"
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        value={formData.do_you_have_an_ndis_plan}
                        onChange={handleRadioChange}
                      />
                      {formData.do_you_have_an_ndis_plan === "Yes" ? (
                        <InputField
                          label="If no, please explain why? (e.g. plan review, need SDA eligibility confirmation)"
                          name="do_you_have_an_ndis_plan___no"
                          value={formData.do_you_have_an_ndis_plan___no}
                          onChange={handleInputChange}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <RadioButtons
                      label="Is Specialist Disability Accommodation eligibility confirmed in your approved NDIS plan?"
                      name="is_specialist_disability_accommodation_eligibility_confirmed_in_your_approved_NDIS_plan"
                      options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                      ]}
                      value={
                        formData.is_specialist_disability_accommodation_eligibility_confirmed_in_your_approved_NDIS_plan
                      }
                      onChange={handleRadioChange}
                    />
                    {formData.is_specialist_disability_accommodation_eligibility_confirmed_in_your_approved_NDIS_plan ===
                    "Yes" ? (
                      <InputField
                        label="If yes, please specify the SDA Building Type"
                        name="if_yes_please_specify_the_sda_building_type"
                        type="text"
                        value={
                          formData.if_yes_please_specify_the_sda_building_type
                        }
                        onChange={handleInputChange}
                      />
                    ) : null}
                  </div>

                  {formData.is_specialist_disability_accommodation_eligibility_confirmed_in_your_approved_NDIS_plan ===
                  "Yes" ? (
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                      <InputField
                        label="If yes, please specify the SDA Design Category"
                        name="if_yes_please_specify_the_sda_design_category"
                        value={
                          formData.if_yes_please_specify_the_sda_design_category
                        }
                        onChange={handleInputChange}
                      />
                      <InputField
                        label="If yes, please provide the SDA funding amount approved in the plan"
                        name="if_yes_please_provide_the_sda_funding_amount_approved_in_the_plan"
                        value={
                          formData.if_yes_please_provide_the_sda_funding_amount_approved_in_the_plan
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <RadioButtons
                    label="Do you receive the Disability Support Pension?"
                    name="do_you_receive_the_disability_support_pension"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={
                      formData.do_you_receive_the_disability_support_pension
                    }
                    onChange={handleRadioChange}
                  />
                  <RadioButtons
                    label="Do you receive the Commonwealth Rental Assistance?"
                    name="do_you_receive_the_commonwealth_rental_assistance"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={
                      formData.do_you_receive_the_commonwealth_rental_assistance
                    }
                    onChange={handleRadioChange}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="If yes, amount per fortnight"
                    name="if_yes_amount_per_fortnight___disability_support_pension"
                    value={
                      formData.if_yes_amount_per_fortnight___disability_support_pension
                    }
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="If yes, amount per fortnight"
                    name="if_yes_amount_per_fortnight___commonwealth_rental_assistance"
                    value={
                      formData.if_yes_amount_per_fortnight___commonwealth_rental_assistance
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="Support coordinator"
                    name="support_coordinator"
                    value={formData.support_coordinator}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Organisation"
                    name="organisation"
                    value={formData.organisation}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Phone (include area code)"
                    name="phone_include_area_code)"
                    value={formData.phone_include_area_code}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="mt-10">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Primary Contact Person
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Primary contact person"
                      name="primary_contact_person_name"
                      value={formData.primary_contact_person_name}
                      onChange={handleInputChange}
                    />
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Phone (include area code)"
                        name="primary_contact_person_phone"
                        value={formData.primary_contact_person_phone}
                        onChange={handleInputChange}
                      />
                      <InputField
                        label="Relationship or Organisation"
                        name="primary_contact_person_relationship_or_organisation)"
                        value={
                          formData.primary_contact_person_relationship_or_organisation
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Address"
                      name="primary_contact_person_address"
                      value={formData.primary_contact_person_address}
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Suburb"
                      name="primary_contact_person_suburb"
                      value={formData.primary_contact_person_suburb}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Postcode"
                        name="primary_contact_person_postcode"
                        value={formData.primary_contact_person_postcode}
                        onChange={handleInputChange}
                      />
                      <SelectField
                        label="State"
                        name="primary_contact_person_state"
                        value={formData.primary_contact_person_state}
                        onChange={handleSelectChange}
                        options={[
                          { value: "", label: "--Select--" },
                          { value: "nsw", label: "New South Wales" },
                          { value: "qld", label: "Queensland" },
                          { value: "sa", label: "South Australia" },
                          { value: "tas", label: "Tasmania" },
                          { value: "vic", label: "Victoria" },
                          { value: "wa", label: "Western Australia" },
                          {
                            value: "act",
                            label: "Australian Capital Territory",
                          },
                          { value: "nt", label: "Northern Territory" },
                        ]}
                      />
                    </div>
                    <InputField
                      label="Email"
                      name="primary_contact_person_email"
                      value={formData.primary_contact_person_email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div>
              <div className="">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Person Completing this Form
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Person completing this form"
                      name="person_completing_this_form"
                      value={formData.person_completing_this_form}
                      onChange={handleInputChange}
                    />
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Phone (include area code)"
                        name="person_completing_this_form_phone"
                        value={formData.person_completing_this_form_phone}
                        onChange={handleInputChange}
                      />
                      <InputField
                        label="Relationship or Organisation"
                        name="person_completing_this_form_relationship_or_organisation)"
                        value={
                          formData.person_completing_this_form_relationship_or_organisation
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Address"
                      name="person_completing_this_form_address"
                      value={formData.person_completing_this_form_address}
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Suburb"
                      name="person_completing_this_form_suburb"
                      value={formData.person_completing_this_form_suburb}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Postcode"
                        name="person_completing_this_form_postcode"
                        value={formData.person_completing_this_form_postcode}
                        onChange={handleInputChange}
                      />
                      <SelectField
                        label="State"
                        name="person_completing_this_form_state"
                        value={formData.person_completing_this_form_state}
                        onChange={handleSelectChange}
                        options={[
                          { value: "", label: "--Select--" },
                          { value: "nsw", label: "New South Wales" },
                          { value: "qld", label: "Queensland" },
                          { value: "sa", label: "South Australia" },
                          { value: "tas", label: "Tasmania" },
                          { value: "vic", label: "Victoria" },
                          { value: "wa", label: "Western Australia" },
                          {
                            value: "act",
                            label: "Australian Capital Territory",
                          },
                          { value: "nt", label: "Northern Territory" },
                        ]}
                      />
                    </div>
                    <InputField
                      label="Email"
                      name="person_completing_this_form_email"
                      value={formData.person_completing_this_form_email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Contact Person for Additional Information
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Contact Person for Additional Information"
                      name="contact_person_for_additional_information"
                      value={formData.contact_person_for_additional_information}
                      onChange={handleInputChange}
                    />
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Phone (include area code)"
                        name="contact_person_for_additional_information_phone"
                        value={
                          formData.contact_person_for_additional_information_phone
                        }
                        onChange={handleInputChange}
                      />
                      <InputField
                        label="Relationship or Organisation"
                        name="contact_person_for_additional_information_relationship_or_organisation)"
                        value={
                          formData.person_completing_this_form_relationship_or_organisation
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <InputField
                      label="Address"
                      name="contact_person_for_additional_information_address"
                      value={
                        formData.contact_person_for_additional_information_address
                      }
                      onChange={handleInputChange}
                    />
                    <InputField
                      label="Suburb"
                      name="contact_person_for_additional_information_suburb"
                      value={
                        formData.contact_person_for_additional_information_suburb
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between">
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="Postcode"
                        name="contact_person_for_additional_information_postcode"
                        value={
                          formData.contact_person_for_additional_information_postcode
                        }
                        onChange={handleInputChange}
                      />
                      <SelectField
                        label="State"
                        name="contact_person_for_additional_information_state"
                        value={
                          formData.contact_person_for_additional_information_state
                        }
                        onChange={handleSelectChange}
                        options={[
                          { value: "", label: "--Select--" },
                          { value: "nsw", label: "New South Wales" },
                          { value: "qld", label: "Queensland" },
                          { value: "sa", label: "South Australia" },
                          { value: "tas", label: "Tasmania" },
                          { value: "vic", label: "Victoria" },
                          { value: "wa", label: "Western Australia" },
                          {
                            value: "act",
                            label: "Australian Capital Territory",
                          },
                          { value: "nt", label: "Northern Territory" },
                        ]}
                      />
                    </div>
                    <InputField
                      label="Email"
                      name="contact_person_for_additional_information_email"
                      value={
                        formData.contact_person_for_additional_information_email
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 5 && (
            <div>
              <div className="">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Your current housing and living situation
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <CheckboxGroup
                    label="Do any of the following apply to you?"
                    name="do_any_of_the_following_apply_to_you"
                    options={[
                      {
                        value:
                          "I am currently homeless or living in temporary or interim accommodation",
                        label:
                          "I am currently homeless or living in temporary or interim accommodation",
                      },
                      {
                        value:
                          "There are risk factors for me or my family or current carer (such as acts of harm or violence resulting in injury)",
                        label:
                          "There are risk factors for me or my family or current carer (such as acts of harm or violence resulting in injury)",
                      },
                      {
                        value:
                          "My family or current carer is ageing or has health concerns and can no longer offer me the support I need",
                        label:
                          "My family or current carer is ageing or has health concerns and can no longer offer me the support I need",
                      },
                    ]}
                    selectedValues={
                      formData.do_any_of_the_following_apply_to_you
                    }
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxGroup
                    label="Please describe your current living situation"
                    name="please_describe_your_current_living_situation"
                    options={[
                      { value: "With family", label: "With family" },
                      {
                        value: "Supported residential services (SRS)",
                        label: "Supported residential services (SRS)",
                      },
                      { value: "Hospital", label: "Hospital" },
                      {
                        value: "Living independently",
                        label: "Living independently",
                      },
                      { value: "Nursing home", label: "Nursing home" },
                      { value: "Another SDA", label: "Another SDA" },
                      { value: "Rehabilitation", label: "Rehabilitation" },
                      {
                        value: "Other",
                        label: "Other (please specify below)",
                      },
                    ]}
                    selectedValues={
                      formData.please_describe_your_current_living_situation
                    }
                    onChange={handleCheckboxChange}
                  />
                  {formData.please_describe_your_current_living_situation.includes(
                    "Other"
                  ) ? (
                    <InputField
                      label="Other (please specify below)"
                      name="please_describe_your_current_living_situation___other"
                      value={
                        formData.please_describe_your_current_living_situation___other
                      }
                      onChange={handleInputChange}
                    />
                  ) : (
                    ""
                  )}
                  <InputField
                    label="Please describe your previous living arrangements over the past 5 years (if different from above)"
                    name="please_describe_your_previous_living_arrangements_over_the_past_5_years"
                    value={
                      formData.please_describe_your_previous_living_arrangements_over_the_past_5_years
                    }
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="mt-10">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  About You
                </h3>
                <div className="flex flex-col gap-[15px] md:gap-[30px] justify-between">
                  <InputField
                    label="Please tell us about your personality:"
                    name="please_tell_us_about_your_personality"
                    value={formData.please_tell_us_about_your_personality}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Please tell us about your hobbies and interests:"
                    name="please_tell_us_about_your_hobbies_and_interests:"
                    value={
                      formData.please_tell_us_about_your_hobbies_and_interests
                    }
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 6 && (
            <div>
              <div className="">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[10px]">
                  Your current support needs
                </h3>
                <p className="text-[18px] text-[#7F7F7F] leading-[1.4em] mb-[10px] md:mb-[30px]">
                  Please attach any relevant assessments or reports when
                  emailing this form.
                </p>
                <InputField
                  label="Current support"
                  name="current_support"
                  value={formData.current_support}
                  onChange={handleInputChange}
                />
                <p className="text-[18px] text-[#25282C] font-[600] leading-[1.4em] mt-[10px] md:mt-[30px]  mb-[10px]">
                  Please attach any relevant assessments or reports when
                  emailing this form.
                </p>
                <p className="text-[18px] text-[#7F7F7F] leading-[1.4em]">
                  Do you have any formal support from service providers? <br />
                  What informal support do you have from family and friends?
                </p>
              </div>
              <div className="mt-[30px]">
                <div className="flex flex-col gap-4">
                  {rows.map((row, index) => (
                    <div
                      key={row.id}
                      className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]"
                    >
                      <InputField
                        label="Phone (include area code)"
                        name="phone"
                        value={row.phone}
                        onChange={e => handleInputChange(e, row.id)}
                      />
                      <InputField
                        label="Relationship or Organisation"
                        name="relationship"
                        value={row.relationship}
                        onChange={e => handleInputChange(e, row.id)}
                      />
                      <button
                        className="self-end relative bottom-[4px]"
                        type="button"
                        onClick={e =>
                          index === rows.length - 1
                            ? addRow(e)
                            : removeRow(row.id, e)
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            index === rows.length - 1
                              ? faPlusCircle
                              : faMinusCircle
                          }
                          className={`text-[35px] shadow-lg rounded-full ${
                            index === rows.length - 1
                              ? "text-[#1C5C4F]"
                              : "text-[#D0F05A]"
                          }`}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 7 && (
            <div>
              <div className="">
                <h3 className="text-[#25282C] text-[24px] font-[700] mb-[30px]">
                  Communication
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <CheckboxGroup
                    label="How do you prefer to communicate? Please select all preferences."
                    name="how_do_you_prefer_to_communicate"
                    options={[
                      {
                        value: "Verbally",
                        label: "Verbally",
                      },
                      {
                        value: "Non-verbally with vocalisations",
                        label: "Non-verbally with vocalisations",
                      },
                      {
                        value: "iPad",
                        label: "iPad",
                      },
                      {
                        value: "Auslan",
                        label: "Auslan",
                      },
                      {
                        value: "Point and gesture",
                        label: "Point and gesture",
                      },
                      {
                        value: "PECS",
                        label: "PECS",
                      },
                      {
                        value: "Makaton",
                        label: "Makaton",
                      },
                      {
                        value: "Other",
                        label: "Other (please specify below)",
                      },
                    ]}
                    selectedValues={formData.how_do_you_prefer_to_communicate}
                    onChange={handleCheckboxChange}
                  />
                  {formData.how_do_you_prefer_to_communicate.includes(
                    "Other"
                  ) ? (
                    <InputField
                      label="Other (please specify below)"
                      name="how_do_you_prefer_to_communicate___other"
                      value={
                        formData.please_describe_your_current_living_situation___other
                      }
                      onChange={handleInputChange}
                    />
                  ) : (
                    ""
                  )}
                  <RadioButtons
                    label="Are you an NDIS participant?"
                    name="are_you_an_ndis_participant_step7"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={formData.are_you_an_ndis_participant_step7}
                    onChange={handleRadioChange}
                  />
                  {formData.are_you_an_ndis_participant_step7 === "Yes" ? (
                    <div className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] justify-between flex-[1]">
                      <InputField
                        label="If yes, who completed this assessment?"
                        name="are_you_an_ndis_participant_step7__yes_who_completed_this_assessment?"
                        value={
                          formData.are_you_an_ndis_participant_step7__yes_who_completed_this_assessment
                        }
                        onChange={handleInputChange}
                      />
                      <InputField
                        label="Date of assessment"
                        name="are_you_an_ndis_participant_step7__yes_date_of_assessment"
                        type="date"
                        value={
                          formData.are_you_an_ndis_participant_step7__yes_date_of_assessment
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <InputField
                    label="How do you express your feelings?"
                    name="how_do_you_express_your_feelings"
                    value={formData.how_do_you_express_your_feelings}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="How do you understand others?"
                    name="how_do_you_understand_others"
                    value={formData.how_do_you_understand_others}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="If you communicate non-verbally, how do you make your needs known?"
                    name="if_you_communicate_non_verbally_how_do_you_make_your_needs_known"
                    value={
                      formData.if_you_communicate_non_verbally_how_do_you_make_your_needs_known
                    }
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 8 && (
            <div>
              <div className="">
                <h3 className="text-[#1C5C4F] text-[24px] font-[700] mb-[30px]">
                  Daily living skills
                </h3>
                <BrandParagraph
                  text={`Please tell us about the level of support you need to do the following activities`}
                />
                <ul className="flex flex-col gap-5 mt-8">
                  <IconListItem
                    text={`<span class="font-[600] text-[#1C5C4F]">No help</span> means you are fully independent and need no help from another person to complete the activity`}
                    ec={`text-[#7F7F7F]`}
                  />
                  <IconListItem
                    text={`<span class="font-[600] text-[#1C5C4F]">Uses aids</span> means you don’t need help from another person, and you use an aid to do the activity by yourself `}
                    ec={`text-[#7F7F7F]`}
                  />
                  <IconListItem
                    text={`<span class="font-[600] text-[#1C5C4F]">Prompting</span> means you need another person to give you reminders during the activity`}
                    ec={`text-[#7F7F7F]`}
                  />
                  <IconListItem
                    text={`<span class="font-[600] text-[#1C5C4F]">Some support</span> means you need another person to prompt you, model the activity and give a you some hands-on support`}
                    ec={`text-[#7F7F7F]`}
                  />
                  <IconListItem
                    text={`<span class="font-[600] text-[#1C5C4F]">Full support</span> means you need another person to physically help you do the activity`}
                    ec={`text-[#7F7F7F]`}
                  />
                </ul>
                <table className="w-full text-left mt-[15px] md:mt-[30px]">
                  <thead>
                    <tr>
                      <th>Support required</th>
                      {supportOptions.map(option => (
                        <th key={option.value}>{option.label}</th>
                      ))}
                      <th>
                        Please describe the support you need with this activity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map(activity => (
                      <tr>
                        <td className="text-[#7F7F7F]">{activity.label}</td>
                        {supportOptions.map(option => (
                          <td key={option.value}>
                            <input
                              type="radio"
                              name={`support_required__${activity.name}`}
                              value={option.value}
                              checked={
                                option.value ===
                                formData[`support_required__${activity.name}`]
                              }
                              onChange={handleRadioChange}
                            />
                          </td>
                        ))}
                        <td>
                          <InputField
                            label=""
                            name={`support_required__${activity.name}__support_activity`}
                            value={
                              formData[
                                `support_required__${activity.name}__support_activity`
                              ]
                            }
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 9 && (
            <div>
              <div className="">
                <h3 className="text-[#25282C] text-[24px] font-[700] mb-[30px]">
                  Equipment
                </h3>
                <div className="flex flex-col gap-[30px]">
                  <RadioButtons
                    label="Do you use any equipment? This includes things like a hoist, walking frame, wheelchair, a commode, hearing aids and glasses"
                    name="do_you_use_any_equipment"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={formData.do_you_use_any_equipment}
                    onChange={handleRadioChange}
                  />
                  {formData.do_you_use_any_equipment === "Yes" ? (
                    <InputField
                      label="Please describe the equipment you use:"
                      name="please_describe_the_equipment_you_use"
                      value={formData.please_describe_the_equipment_you_use}
                      onChange={handleInputChange}
                    />
                  ) : (
                    ""
                  )}
                  <RadioButtons
                    label="If you use equipment, do you need assistance to use the equipment?"
                    name="do_you_assistance_to_use_the_equipment"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={formData.do_you_assistance_to_use_the_equipment}
                    onChange={handleRadioChange}
                  />
                  {formData.do_you_assistance_to_use_the_equipment === "Yes" ? (
                    <InputField
                      label="Please describe the equipment you use:"
                      name="please_describe_the_equipment_you_use"
                      value={formData.please_describe_the_equipment_you_use}
                      onChange={handleInputChange}
                    />
                  ) : (
                    ""
                  )}
                  <RadioButtons
                    label="If you need assistance to use equipment, will staff require specific training to help you use the equipment?"
                    name="will_staff_require_specific_training_to_help_you_use_the_equipment"
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    value={formData.will_staff_require_specific_training_to_help_you_use_the_equipment}
                    onChange={handleRadioChange}
                  />
                  {formData.will_staff_require_specific_training_to_help_you_use_the_equipment === "Yes" ? (
                    <InputField
                      label="Please describe the training staff will need:"
                      name="Please_describe_the_training_staff_will_need"
                      value={
                        formData.Please_describe_the_training_staff_will_need
                      }
                      onChange={handleInputChange}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center gap-[30px] text-center mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="secondary-transparent-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Back
                </button>

                <button
                  type="button"
                  onClick={nextStep}
                  className="primary-btn font-[600] min-w-[130px] text-[14px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300"
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </Layout>
  )
}

export default IntakeForm
