import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerSdaInvestorPage from "../../components/molecules/InnerSdaInvestorPage"
import { highPhysicalSupportPage } from "../../content/SdaInvestors"

const HighPhysicalSupport = () => (
  <Layout>
    <Seo title="High Physical Support - Investers" />
    <InnerSdaInvestorPage data={highPhysicalSupportPage} />
  </Layout>
)

export default HighPhysicalSupport
