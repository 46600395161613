import React from "react"

const InputField = ({ componentEC, label, type = "text", name, value, placeholder, onChange }) => {
  return (
    <div className={`flex-[1] ${componentEC}`}>
      <label className="flex flex-col gap-[10px] text-[#25282C] text-[18px] leading-[1.4em] font-[600]">
        {label !== undefined ? label : ""}
        <input type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} className="bg-[#F9FAF5] border border-[#D4D2D2] rounded-[4px] font-[400] p-[10px] !outline-0" />
      </label>
    </div>
  )
}

export default InputField
