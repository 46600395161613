import React from "react"

const RadioButtonsAsToggleButtons = ({
  componentEC,
  label,
  name,
  options,
  value,
  onChange,
}) => {
  return (
    <div className={`flex-[1] flex flex-col gap-[10px] ${componentEC}`}>
      <label className="text-[#25282C] text-[18px] leading-[1.4em] font-[600]">
        {label}
      </label>
      <div className="flex border border-[#1C5C4F] rounded-[6px] max-w-[max-content] p-[2px]">
        {options.map(option => (
          <label
            key={option.value}
            className={`min-w-[110px] text-[#7F7F7F] text-center block p-[8px] rounded-[6px] cursor-pointer font-[600] transition-all ${
              value === option.value ? "bg-[#D9F27E] !text-[#25282C]" : "bg-transparent"
            }`}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              className="opacity-0"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioButtonsAsToggleButtons
