import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerSdaInvestorPage from "../../components/molecules/InnerSdaInvestorPage"
import { fullyAccessiblePage } from "../../content/SdaInvestors"

const FullyAccessible = () => (
  <Layout>
    <Seo title="Fully Accessible - Investers" />
    <InnerSdaInvestorPage data={fullyAccessiblePage} />
  </Layout>
)

export default FullyAccessible
