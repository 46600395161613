import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerSdaInvestorPage from "../../components/molecules/InnerSdaInvestorPage"
import { robustSdaPage } from "../../content/SdaInvestors"

const RobustSda = () => (
  <Layout>
    <Seo title="Robust SDA - Investers" />
    <InnerSdaInvestorPage data={robustSdaPage} />
  </Layout>
)

export default RobustSda
