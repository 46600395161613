import React from "react"

const SelectField = ({
  componentEC,
  label,
  name,
  value,
  onChange,
  options,
}) => {
  return (
    <div className={`flex-[1] ${componentEC}`}>
      <label className="flex flex-col gap-[10px] text-[#25282C] text-[18px] leading-[1.4em] font-[600]">
        {label}
        <select
          name={name}
          value={value}
          onChange={onChange}
          className="bg-[#F9FAF5] border border-[#D4D2D2] rounded-[4px] font-[400] p-[10px] !outline-0"
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}

export default SelectField
