import React from "react"

const ProgressBarForFormSteps = ({steps, activeStep}) => {
  return (
    <div className="w-full flex items-center justify-center mb-6">
      <div className="flex items-center justify-center flex-wrap">
        {steps.map((s, index) => (
          <div key={s} className="flex items-center justify-center relative">
            {/* Step Bubble */}
            <div
              className={`w-[10px] md:w-[14px] h-[10px] md:h-[14px] p-[1px] rounded-full border-2 transition-all ${
                activeStep >= s ? "border-[#1C5C4F]" : "border-[#D4D2D2]"
              }`}
            >
              <div
                className={`w-full h-full rounded-full ${
                    activeStep >= s ? "bg-[#1C5C4F]" : ""
                }`}
              ></div>
            </div>

            {/* Connect bubbles with lines */}
            {index !== steps.length - 1 && (
              <div
                className={`w-4 md:w-10 h-[2px] ${
                    activeStep > s ? "bg-[#1C5C4F]" : "bg-[#D4D2D2]"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProgressBarForFormSteps
