import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerSdaInvestorPage from "../../components/molecules/InnerSdaInvestorPage"
import { improvedLiveabilityPage } from "../../content/SdaInvestors"

const ImprovedLiveability = () => (
  <Layout>
    <Seo title="Improved Liveability - Investers" />
    <InnerSdaInvestorPage data={improvedLiveabilityPage} />
  </Layout>
)

export default ImprovedLiveability
